import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { configureARelyingPartyTrust } from './1-configure-a-relying-party-trust';
import { chooseAccessPolicy } from './2-choose-access-policy';
import { configureClaimsIssuancePolicy } from './3-configure-claims-issuance-policy';
import { uploadMetadataUrl } from './4-upload-metadata-url';

interface AdfsSamlStepsProps {}

export type AdfsSamlFormFields = {
  saml_idp_metadata_url: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<AdfsSamlFormFields>[] = generateSsoStepData([
  configureARelyingPartyTrust,
  chooseAccessPolicy,
  configureClaimsIssuancePolicy,
  uploadMetadataUrl,
]);

export const AdfsSamlSteps: FC<Readonly<AdfsSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
