import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createSamlIntegration } from './1-create-saml-integration';
import { configureAttributeStatements } from './2-configure-attribute-statements';
import { submitApplicationFeedback } from './3-submit-application-feedback';
import { addUsersToSamlApp } from './4-add-users-to-saml-app';
import { uploadIdentityProviderMetadata } from './5-upload-identity-provider-metadata';

interface OktaSamlStepsProps {}

export type OktaStepsFormFields = {
  saml_idp_metadata_url: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<OktaStepsFormFields>[] = generateSsoStepData([
  createSamlIntegration,
  configureAttributeStatements,
  submitApplicationFeedback,
  addUsersToSamlApp,
  uploadIdentityProviderMetadata,
]);

export const OktaSamlSteps: FC<Readonly<OktaSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
