import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Ol } from '../../../../../components/typography';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MetadataXmlStep } from '../../metadata-step';
import { JumpCloudSamlFormFields } from './jump-cloud-saml-steps';

const Component: FC = () => (
  <MetadataXmlStep
    idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
    idpSsoUrlPlaceholder="https://sso.jumpcloud.com/..."
  >
    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Ol className="my-4">
      <li>Click on the “SSO” tab of the SAML app you just created.</li>

      <li>Be sure to check "Declare Redirect Endpoint".</li>

      <Img
        className="my-8"
        height={1226}
        src="/images/3a24425c-dd94-4b86-8b3a-f476b6f55665.png"
        width={2120}
      />

      <li>Click the "Export Metadata" button under "JumpCloud Metadata".</li>

      <Img
        className="my-8"
        height={1226}
        src="/images/abd1a6ff-ec7b-427b-b7d5-0813af0107ee.png"
        width={2120}
      />

      <li>A file will be downloaded. Upload it below.</li>
    </Ol>
  </MetadataXmlStep>
);

export const uploadIdentityProviderMetadata: StepData<JumpCloudSamlFormFields> =
  {
    title: 'Upload Identity Provider Metadata',
    component: Component,
    formKeys: [
      'saml_idp_entity_id',
      'saml_idp_metadata_xml',
      'saml_idp_url',
      'saml_x509_certificates',
    ],
  };
