import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { setupUnsolicitedSaml } from './1-setup-unsolicited-saml';
import { configureSamlAttributes } from './2-configure-saml-attributes';
import { uploadIdentityProviderMetadata } from './3-upload-identity-provider-metadata';

interface ShibbolethUnsolicitedSamlStepsProps {}

export type ShibbolethUnsolicitedSamlFormFields = {
  saml_idp_metadata_xml: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<ShibbolethUnsolicitedSamlFormFields>[] =
  generateSsoStepData([
    setupUnsolicitedSaml,
    configureSamlAttributes,
    uploadIdentityProviderMetadata,
  ]);

export const ShibbolethUnsolicitedSamlSteps: FC<
  Readonly<ShibbolethUnsolicitedSamlStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
