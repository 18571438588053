import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createASamlApplication } from './1-create-a-saml-application';
import { configureSamlClaims } from './2-configure-saml-claims';
import { basicSamlConfiguration } from './3-basic-saml-configuration';

interface OracleSamlStepsProps {}

export type OracleSamlFormFields = {
  saml_idp_metadata_url: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<OracleSamlFormFields>[] = generateSsoStepData([
  createASamlApplication,
  configureSamlClaims,
  basicSamlConfiguration,
]);

export const OracleSamlSteps: FC<Readonly<OracleSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
