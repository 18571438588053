import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MetadataXmlStep } from '../../metadata-step';
import { GoogleSamlFormFields } from './google-saml-steps';

const Component: FC = () => (
  <MetadataXmlStep
    idpEntityIdPlaceholder="https://accounts.google.com/o/saml2?idpid=C00l3qzl9"
    idpSsoUrlPlaceholder="https://accounts.google.com/o/saml2/idp?idpid=C00l3qzl9"
  >
    <Text>
      Select the "Download Metadata" button to download the metadata file, and
      upload it below. Click "Continue".
    </Text>

    <Img
      className="my-8"
      height={1001}
      src="/images/edef0a99-4ff2-4453-99e3-2787d5dc30b8.png"
      width={1239}
    />
  </MetadataXmlStep>
);

export const uploadIdentityProviderMetadata: StepData<GoogleSamlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  component: Component,
  formKeys: [
    'saml_idp_entity_id',
    'saml_idp_metadata_xml',
    'saml_idp_url',
    'saml_x509_certificates',
  ],
};
