import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createASamlApplication } from './1-create-a-saml-application';
import { basicSamlConfiguration } from './2-basic-saml-configuration';
import { advancedSamlConfiguration } from './3-advanced-saml-configuration';
import { configureParameters } from './4-configure-parameters';
import { uploadOneLoginMetadataFile } from './5-upload-onelogin-metadata-file';

interface OneLoginSamlStepsProps {}

const stepsData: StepData[] = generateSsoStepData([
  createASamlApplication,
  basicSamlConfiguration,
  advancedSamlConfiguration,
  configureParameters,
  uploadOneLoginMetadataFile,
]);

export const OneLoginSamlSteps: FC<Readonly<OneLoginSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
