import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createEnterpriseApplication } from './1-create-enterprise-application';
import { basicSamlConfiguration } from './2-basic-saml-configuration';
import { userAttributesAndClaims } from './3-user-attributes-and-claims';
import { assignPeopleAndGroups } from './4-assign-people-and-groups';
import { uploadIdpMetadata } from './5-upload-idp-metadata';

interface AzureSamlStepsProps {}

export type AzureSamlFormFields = {
  saml_idp_metadata_url: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<AzureSamlFormFields>[] = generateSsoStepData([
  createEnterpriseApplication,
  basicSamlConfiguration,
  userAttributesAndClaims,
  assignPeopleAndGroups,
  uploadIdpMetadata,
]);

export const AzureSamlSteps: FC<Readonly<AzureSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
