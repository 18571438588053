import {
  Alert,
  Button,
  Card,
  Dialog,
  Text,
} from '@workos-inc/component-library';
import { useState } from 'react';
import {
  DirectoryCustomAttributeFragment as DirectoryCustomAttribute,
  DirectoryFragment as Directory,
} from '../../../../../graphql/generated';
import { usePortalSession } from '../../../../components/portal-session-provider';
import {
  CoreAttribute,
  coreAttributeToLabel,
} from '../../../../interfaces/core-attribute';
import { graphql } from '../../../../utils/graphql';
import { useDsyncStore } from '../../dsync-store-provider';
import { MapAttributes } from '../../map-attributes';
import { AttributeContainer } from '../../map-attributes/attribute-container';
import { MapAttribute } from '../../map-attributes/map-attribute';

export const DirectoryAttributeMappings = () => {
  const { appName } = usePortalSession();
  const { setDsyncStore, directory, directoryCustomAttributes } =
    useDsyncStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEditSuccessFlash, setShowEditSuccessFlash] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasRequestError, setHasRequestError] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [customMappingValues, setCustomMappingValues] = useState(() =>
    initializeCustomMappingValues({ directory, directoryCustomAttributes }),
  );
  const [coreMappingValues, setCoreMappingValues] = useState(() =>
    initializeCoreMappingValues(directory),
  );

  const hasCoreAttributeMap = !!directory?.attributeMap;

  const isValid = validateMappingValues({
    customMappingValues,
    coreMappingValues,
    hasCoreAttributeMap,
  });
  const disabled = isLoading;

  const closeModal = () => setIsModalOpen(false);

  const handleSaveMappingsAccept = async () => {
    setHasRequestError(false);

    if (!isValid) {
      setShowValidationErrors(true);
      return;
    }

    setIsLoading(true);

    try {
      const updatedDirectory = await updateDirectoryMappings({
        directory,
        coreMappingValues,
        customMappingValues,
      });

      closeModal();
      setShowEditSuccessFlash(true);
      setDsyncStore({ directory: updatedDirectory });
    } catch (e) {
      setHasRequestError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (!directoryCustomAttributes.length) {
    return null;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Attribute Mapping</Card.Title>
      </Card.Header>

      <Card.Body>
        {showEditSuccessFlash && (
          <Alert appearance="green" className="mb-6">
            <Text inheritColor as="p">
              Attribute mappings successfully saved.
            </Text>
          </Alert>
        )}

        <Text>
          Attributes are mapped from your Directory Provider's values to
          attributes expected by the application.
        </Text>

        <MapAttributes
          readonly
          appName={appName}
          directory={directory}
          directoryCustomAttributes={directoryCustomAttributes}
        />

        <Button className="mt-4" onClick={() => setIsModalOpen(true)}>
          Edit Attribute Map
        </Button>
      </Card.Body>

      <Dialog
        acceptText="Save Mappings"
        description="Map attributes from your Directory Provider's responses to the
            attributes shown below."
        onAccept={handleSaveMappingsAccept}
        onOpenChange={(open) => setIsModalOpen(open)}
        open={isModalOpen}
        title="Edit Attribute Map"
      >
        <AttributeContainer>
          <Text weight="bold">Directory Provider Value</Text>
          <div className="mx-4 min-w-[16px]" />
          <Text weight="bold">{appName} Attribute</Text>
        </AttributeContainer>

        {hasCoreAttributeMap &&
          Object.entries(coreMappingValues).map(([name, value]) => (
            <MapAttribute
              key={name}
              attributeId={name as CoreAttribute}
              disabled={disabled}
              name={coreAttributeToLabel(name as CoreAttribute)}
              onChange={({ attributeId, value }) =>
                setCoreMappingValues({
                  ...coreMappingValues,
                  [attributeId]: value,
                })
              }
              showValidationErrors={showValidationErrors}
              value={value}
            />
          ))}

        {directoryCustomAttributes.length &&
          directoryCustomAttributes.map((customAttribute) => (
            <MapAttribute
              key={customAttribute.id}
              attributeId={customAttribute.id}
              disabled={disabled}
              name={customAttribute.name}
              onChange={({ attributeId, value }) =>
                setCustomMappingValues(
                  new Map(customMappingValues.set(attributeId, value)),
                )
              }
              showValidationErrors={showValidationErrors}
              value={customMappingValues.get(customAttribute.id) ?? ''}
            />
          ))}

        {!isValid && showValidationErrors && (
          <Alert appearance="red">
            <Text inheritColor as="p">
              Make sure to add an attribute for each mapping before proceeding.
            </Text>
          </Alert>
        )}

        {hasRequestError && (
          <Alert appearance="red">
            <Text inheritColor as="p">
              Something went wrong saving your mappings. Please try again.
            </Text>
          </Alert>
        )}
      </Dialog>
    </Card>
  );
};

const initializeCustomMappingValues = ({
  directory,
  directoryCustomAttributes,
}: {
  directory: Directory;
  directoryCustomAttributes: DirectoryCustomAttribute[];
}) =>
  new Map([
    ...directoryCustomAttributes?.map(({ id }) => [id, ''] as const),
    ...directory.customAttributeMappings.map(
      ({ customAttributeId, attribute }) =>
        [customAttributeId, attribute] as const,
    ),
  ]);

const initializeCoreMappingValues = (
  directory: Directory,
): Record<CoreAttribute, string> => ({
  externalId: directory.attributeMap?.attributes.externalId ?? '',
  username: directory.attributeMap?.attributes.username ?? '',
  emails: directory.attributeMap?.attributes.emails ?? '',
  firstName: directory.attributeMap?.attributes.firstName ?? '',
  lastName: directory.attributeMap?.attributes.lastName ?? '',
  groupName: directory.attributeMap?.attributes.groupName ?? '',
});

const updateDirectoryMappings = async ({
  directory,
  coreMappingValues,
  customMappingValues,
}: {
  directory: Directory;
  coreMappingValues: Record<CoreAttribute, string>;
  customMappingValues: Map<string, string>;
}): Promise<Directory> => {
  const customResponse = await graphql().SetDirectoryCustomAttributeMappings({
    input: {
      directoryId: directory.id,
      mappings: [...customMappingValues].map(
        ([customAttributeId, attribute]) => ({
          customAttributeId,
          attribute,
        }),
      ),
    },
  });

  let updatedAttributeMap = directory.attributeMap;

  if (!!directory.attributeMap) {
    const attributeMapResponse = await graphql().UpdateDirectoryAttributeMap({
      input: {
        directoryAttributeMapId: directory.attributeMap.id,
        externalIdAttribute: coreMappingValues.externalId,
        usernameAttribute: coreMappingValues.username,
        emailsAttribute: coreMappingValues.emails,
        firstNameAttribute: coreMappingValues.firstName,
        lastNameAttribute: coreMappingValues.lastName,
        groupNameAttribute: coreMappingValues.groupName,
      },
    });

    updatedAttributeMap =
      attributeMapResponse.data?.portal_updateDirectoryAttributeMap
        ?.directoryAttributeMap;
  }

  if (
    customResponse.data?.portal_setDirectoryCustomAttributeMappings
      .__typename === 'Portal_DirectoryCustomAttributeMappingsSet'
  ) {
    return {
      ...directory,
      attributeMap: updatedAttributeMap,
      customAttributeMappings:
        customResponse.data.portal_setDirectoryCustomAttributeMappings.mappings,
    };
  } else {
    throw new Error('Failed to update directory attribute mappings.');
  }
};

const validateMappingValues = ({
  customMappingValues,
  coreMappingValues,
  hasCoreAttributeMap,
}: {
  customMappingValues: Map<string, string>;
  coreMappingValues: Record<CoreAttribute, string>;
  hasCoreAttributeMap: boolean;
}): boolean =>
  [...customMappingValues.values()].every(Boolean) &&
  (!hasCoreAttributeMap || Object.values(coreMappingValues).every(Boolean));
