import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createANewSaasApplication } from './1-create-a-new-saas-application';
import { basicSamlConfiguration } from './2-basic-saml-configuration';
import { advancedSamlConfiguration } from './3-advanced-saml-configuration';
import { configureAttributeMap } from './4-configure-attribute-map';
import { uploadMetadataUrl } from './5-upload-metadata-url';

interface VMwareSamlStepsProps {}

export type VMwareSamlFormFields = {
  saml_idp_metadata_url: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<VMwareSamlFormFields>[] = generateSsoStepData([
  createANewSaasApplication,
  basicSamlConfiguration,
  advancedSamlConfiguration,
  configureAttributeMap,
  uploadMetadataUrl,
]);

export const VMwareSamlSteps: FC<Readonly<VMwareSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
