import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MetadataUrlStep } from '../../metadata-step';
import { KeycloakSamlFormFields } from './keycloak-saml-steps';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://mykeycloak.com/123-abc/"
    idpSsoUrlPlaceholder="http://mykeycloak.com/auth/realms/myrealm/protocol/saml"
    metadataUrlPlaceholder="https://mykeycloak.com/auth/realms/myrealm/protocol/saml/descriptor"
  >
    <Text>
      Select "Realm Settings" in the left sidebar navigation menu, and copy the
      "SAML 2.0 Identity Provider Metadata" link on the General page, and input
      it below.
    </Text>

    <Img
      className="my-8"
      height={1360}
      src="/images/d77e0885-48e9-4cc0-83ae-02515cc16046.png"
      width={2272}
    />
  </MetadataUrlStep>
);

export const uploadIdpMetadata: StepData<KeycloakSamlFormFields> = {
  title: 'Upload IdP Metadata',
  component: Component,
  formKeys: [
    'saml_idp_entity_id',
    'saml_idp_metadata_url',
    'saml_idp_url',
    'saml_x509_certificates',
  ],
};
