import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createAndConfigureAClassLinkSamlApplication } from './1-create-and-configure-a-classlink-saml-application';
import { uploadYourIdentityProviderMetadata } from './2-upload-your-identity-provider-metadata';
import { configureYourSamlClaims } from './3-configure-your-saml-claims';

interface ClassLinkSamlStepsProps {}

export type ClassLinkSamlFormFields = {
  saml_idp_metadata_url: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<ClassLinkSamlFormFields>[] = generateSsoStepData([
  createAndConfigureAClassLinkSamlApplication,
  uploadYourIdentityProviderMetadata,
  configureYourSamlClaims,
]);

export const ClassLinkSamlSteps: FC<Readonly<ClassLinkSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
