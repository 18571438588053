import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MetadataUrlStep } from '../../metadata-step';
import { PingOneSamlFormFields } from './pingone-saml-steps';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
    idpSsoUrlPlaceholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
    metadataUrlPlaceholder="https://admin-api.pingone.com/latest/metadata/..."
  >
    <Text>
      At the end of the Application Setup Wizard, you'll be given an overview of
      your Identity Provider metadata.
    </Text>

    <Img
      height={1828}
      src="/images/b6b0ae64-d298-449a-85f7-ab7f896945c9.png"
      width={2148}
    />

    <Text>Copy and paste the "SAML Metadata URL" in the field below.</Text>
  </MetadataUrlStep>
);

export const uploadMetadataFromPingOne: StepData<PingOneSamlFormFields> = {
  title: 'Upload Metadata from PingOne',
  component: Component,
  formKeys: [
    'saml_idp_entity_id',
    'saml_idp_metadata_url',
    'saml_idp_url',
    'saml_x509_certificates',
  ],
};
