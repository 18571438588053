import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MetadataUrlStep } from '../../metadata-step';
import { MiniOrangeSamlFormFields } from './mini-orange-saml-steps';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://login.xecurify.com/moas/..."
    idpSsoUrlPlaceholder="https://login.xecurify.com/moas/idp/samlsso/..."
    metadataUrlPlaceholder="https://login.xecurify.com/moas/metadata/saml/..."
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      Back on the "Apps" tab of the miniOrange Dashboard, click "Select" next to
      the app you've created. From the dropdown, select "Metadata".
    </Text>

    <Img
      className="my-8"
      height={1606}
      src="/images/86d9be6b-afa8-49c7-9c97-3226ccc6e286.png"
      width={2730}
    />

    <Text>
      Under the "INFORMATION REQUIRED TO SET MINIORANGE AS IDP" section, click
      the icon next to "Metadata URL" to copy it to your clipboard.
    </Text>

    <Img
      className="my-8"
      height={1606}
      src="/images/502a85fb-e6d0-4a89-ad49-280c2ee8d4ab.png"
      width={2730}
    />

    <Text>
      Input the IdP Metadata URL below and select "Continue to Next Step".
    </Text>
  </MetadataUrlStep>
);

export const uploadIdentityProviderMetadata: StepData<MiniOrangeSamlFormFields> =
  {
    title: 'Upload Identity Provider Metadata',
    component: Component,
    formKeys: [
      'saml_idp_entity_id',
      'saml_idp_metadata_url',
      'saml_idp_url',
      'saml_x509_certificates',
    ],
  };
