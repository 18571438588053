import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MetadataUrlStep } from '../../metadata-step';
import { CasSamlFormFields } from './cas-saml-steps';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://mycasserver.com/abc123..."
    idpSsoUrlPlaceholder="https://mycasserver.com/..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      To connect with your CAS instance, please upload the CAS SAML application
      metadata.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      Upload the IdP metadata URL from your SAML settings. Alternatively, you
      can manually configure the connection by providing the SSO URL, IdP Issuer
      and X.509 Certificate.
    </Text>
  </MetadataUrlStep>
);

export const uploadYourIdentityProviderMetadata: StepData<CasSamlFormFields> = {
  title: 'Upload your Identity Provider Metadata',
  component: Component,
  formKeys: [
    'saml_idp_entity_id',
    'saml_idp_metadata_url',
    'saml_idp_url',
    'saml_x509_certificates',
  ],
};
