import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { addCustomSamlApplication } from './1-add-custom-saml-application';
import { enterDetailsForYourCustomApp } from './2-enter-details-for-your-custom-app';
import { uploadIdentityProviderMetadata } from './3-upload-indentity-provider-metadata';
import { enterServiceProviderDetails } from './4-enter-service-provider-details';
import { configureAttributeMapping } from './5-configure-attribute-mapping';
import { configureUserAccess } from './6-configure-user-access';

interface GoogleSamlStepsProps {}

export type GoogleSamlFormFields = {
  saml_idp_metadata_xml: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<GoogleSamlFormFields>[] = generateSsoStepData([
  addCustomSamlApplication,
  enterDetailsForYourCustomApp,
  uploadIdentityProviderMetadata,
  enterServiceProviderDetails,
  configureAttributeMapping,
  configureUserAccess,
]);

export const GoogleSamlSteps: FC<Readonly<GoogleSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
