import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MetadataUrlStep } from '../../metadata-step';
import { CyberArkSamlFormFields } from './cyberark-saml-steps';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="http://www.cyberark.com/exkgq2c19CUpt2Brr46"
    idpSsoUrlPlaceholder="http://www.cyberark.com/exkgq2c19CUpt2Brr46"
    metadataUrlPlaceholder="https://cyberark-metadata-url"
  >
    <Text>
      You can find the Metadata URL under the "Trust" tab of the SAML app in the
      "Identity Provider Configuration" section. Enter the value into the field
      below.
    </Text>

    <Img
      className="my-8"
      height={814}
      src="/images/dd386d12-5aaa-4446-9a91-6a047fc4e200.png"
      width={1423}
    />
  </MetadataUrlStep>
);

export const uploadIdentityProviderMetadata: StepData<CyberArkSamlFormFields> =
  {
    title: 'Upload Identity Provider Metadata',
    component: Component,
    formKeys: [
      'saml_idp_entity_id',
      'saml_idp_metadata_url',
      'saml_idp_url',
      'saml_x509_certificates',
    ],
  };
