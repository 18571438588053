import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createSamlApplication } from './1-create-saml-application';
import { submitAcsUrlAndEntityId } from './2-submit-acs-url-and-entity-id';
import { configureAttributeStatements } from './3-configure-attribute-statements';
import { addUsersToSamlApp } from './4-add-users-to-saml-app';
import { uploadIdentityProviderMetadata } from './5-upload-identity-provider-metadata';

interface LastPassSamlStepsProps {}

export type LastPassSamlFormFields = {
  saml_idp_metadata_xml: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<LastPassSamlFormFields>[] = generateSsoStepData([
  createSamlApplication,
  submitAcsUrlAndEntityId,
  configureAttributeStatements,
  addUsersToSamlApp,
  uploadIdentityProviderMetadata,
]);

export const LastPassSamlSteps: FC<Readonly<LastPassSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
