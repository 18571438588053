import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createAndConfigureASimpleSamlPhpSamlApplication } from './1-create-and-configure-a-simple-saml-php-saml-application';
import { uploadIdentityProviderMetadata } from './2-upload-identity-provider-metadata';
import { configureYourSamlClaims } from './3-configure-your-saml-claims';

interface SimpleSamlPhpSamlStepsProps {}

export type SimpleSamlPhpFormFields = {
  saml_idp_metadata_url: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<SimpleSamlPhpFormFields>[] = generateSsoStepData([
  createAndConfigureASimpleSamlPhpSamlApplication,
  uploadIdentityProviderMetadata,
  configureYourSamlClaims,
]);

export const SimpleSamlPhpSamlSteps: FC<
  Readonly<SimpleSamlPhpSamlStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
