import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createSamlApplication } from './1-create-saml-application';
import { uploadIdentityProviderMetadata } from './2-upload-identity-provider-metadata';

interface Auth0SamlStepsProps {}

export type Auth0SamlFormFields = {
  saml_idp_metadata_url: string;
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const stepsData: StepData<Auth0SamlFormFields>[] = generateSsoStepData([
  createSamlApplication,
  uploadIdentityProviderMetadata,
]);

export const Auth0SamlSteps: FC<Readonly<Auth0SamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
