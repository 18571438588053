import { Skeleton, Text } from '@workos-inc/component-library';
import classNames from 'classnames';
import { cloneElement, FC, ReactElement } from 'react';
import { IconProps } from 'react-feather';

interface JsonSchemaNavItemProps {
  title: string;
  selected: boolean;
  subtitle: string;
  icon: ReactElement<IconProps>;
  onSelect?: () => void;
}

export const JsonSchemaNavItem: FC<JsonSchemaNavItemProps> = ({
  title,
  subtitle,
  selected,
  icon,
  onSelect: handleOnSelect,
}) => (
  <li>
    <button
      className={classNames(
        // eslint-disable-next-line max-len
        'w-full p-3 text-sm text-left rounded-md cursor-pointer transition-colors duration-200 ease-in-out max-w-xs hover:bg-[#F2F2F3] dark:hover:bg-gray-darkmode-100',
        selected && 'bg-[#F2F2F3] dark:bg-gray-darkmode-100',
        !title && 'pointer-events-none',
      )}
      onClick={handleOnSelect}
    >
      <div className="inline-flex w-full items-center gap-1 text-gray-lightmode-400 dark:text-gray-darkmode-400">
        {cloneElement(icon, {
          ...icon.props,
          size: 14,
          className: classNames(
            'shrink-0 transition-opacity',
            !selected && 'opacity-50',
            icon.props.className,
          ),
        })}
        <Skeleton className="w-6/12 py-0" show={!!title}>
          <Text
            inheritColor
            as="p"
            className="truncate"
            size="small"
            weight={selected ? 'medium' : 'normal'}
          >
            {title}
          </Text>
        </Skeleton>
      </div>
      <Skeleton className="w-full py-0" show={!!title}>
        <Text as="p" className="truncate" size="small">
          {subtitle}
        </Text>
      </Skeleton>
    </button>
  </li>
);

export const JsonSchemaNav: FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <nav>
    <ul className="flex flex-col gap-y-0.5">{children}</ul>
  </nav>
);
