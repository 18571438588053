import { Skeleton, Text } from '@workos-inc/component-library';
import { useEffect, useMemo, useState } from 'react';
import { Activity, Calendar, Crosshair, Globe } from 'react-feather';
import { useParams } from 'react-router-dom';
import { AuditLogEventQuery } from '../../../../graphql/generated';
import { graphql } from '../../../../utils/graphql';
import { AuditLogsActorIcon, AuditLogsBreadcrumb } from '../audit-logs-table';
import { formatAuditLogEventDate } from '../format-audit-log-event-date';
import {
  JsonSchema,
  JsonSchemaNav,
  JsonSchemaNavItem,
} from '../json-schema-explorer';
import { PageWrapper } from '../page-wrapper/page-wrapper';

export const AuditLogEvent = () => {
  const { eventId } = useParams();
  const [highlightedProperty, setHighlightedProperty] = useState<string>();
  const [data, setData] = useState<AuditLogEventQuery>();

  useEffect(() => {
    async function fetchEventData() {
      if (!eventId) {
        throw new Error("'eventId' is required");
      }

      const { data: eventData } = await graphql().auditLogEvent({
        id: eventId,
      });
      setData(eventData);
    }

    void fetchEventData();
  }, [eventId]);

  const auditLog = data?.portal_auditLogEvent;
  const time =
    formatAuditLogEventDate(auditLog?.occurredAt ?? '', 'H:mm:ss z') ?? '';
  const date =
    formatAuditLogEventDate(auditLog?.occurredAt ?? '', 'MMMM dd, yyyy') ?? '';
  const rawData = useMemo(
    () => (auditLog?.data ? JSON.parse(auditLog.data) : undefined),
    [auditLog?.data],
  );

  return (
    <PageWrapper>
      <div className="mb-4 flex w-full flex-col gap-2">
        <AuditLogsBreadcrumb auditLogId={eventId} />
      </div>

      <div className="grid grid-cols-[288px,1fr] overflow-hidden rounded-md ring-1 ring-gray-lightmode-200 dark:ring-gray-darkmode-200">
        <div className="border-r border-gray-lightmode-200 p-3 dark:border-gray-darkmode-200">
          <div className="p-3">
            <Text as="h1" size="large" weight="medium">
              Audit log event
            </Text>
            <Skeleton className="w-full py-0" show={!!auditLog}>
              <Text as="p" className="-mt-0.5 truncate" size="small">
                {auditLog?.id}
              </Text>
            </Skeleton>
          </div>

          <div className="my-2 border-b border-gray-lightmode-200 dark:border-gray-darkmode-200" />

          <JsonSchemaNav>
            <JsonSchemaNavItem
              icon={<AuditLogsActorIcon actor={auditLog?.actor} />}
              onSelect={() => setHighlightedProperty('actor')}
              selected={highlightedProperty === 'actor'}
              subtitle={auditLog?.actor.type ?? ''}
              title={auditLog?.actor.name ?? ''}
            />

            <JsonSchemaNavItem
              icon={<Activity />}
              onSelect={() => setHighlightedProperty('action')}
              selected={highlightedProperty === 'action'}
              subtitle={auditLog?.id ?? ''}
              title={auditLog?.action ?? ''}
            />

            <JsonSchemaNavItem
              icon={<Calendar />}
              onSelect={() => setHighlightedProperty('occurred_at')}
              selected={highlightedProperty === 'occurred_at'}
              subtitle={time}
              title={date}
            />

            <JsonSchemaNavItem
              icon={<Crosshair />}
              onSelect={() => setHighlightedProperty('targets')}
              selected={highlightedProperty === 'targets'}
              subtitle={
                auditLog?.targets.map((target) => target.type).join(', ') ?? ''
              }
              title={getTargetCountTitle(auditLog?.targets ?? [])}
            />

            {rawData && rawData.context && (
              <JsonSchemaNavItem
                icon={<Globe />}
                onSelect={() => setHighlightedProperty('context')}
                selected={highlightedProperty === 'context'}
                subtitle={rawData.context.user_agent ?? ''}
                title={rawData.context.location ?? ''}
              />
            )}
          </JsonSchemaNav>
        </div>

        <div className="relative overflow-auto bg-gray-lightmode-100 dark:bg-gray-darkmode-100">
          <JsonSchema
            highlightedProperty={highlightedProperty}
            json={rawData}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

/**
 * Get the count of targets and returns it as a nicely formatted string
 */
const getTargetCountTitle = (targets: { type: string }[]): string => {
  const targetCache: Record<string, number> = {};

  targets.forEach((target) => {
    if (!targetCache[target.type]) {
      targetCache[target.type] = 0;
    }

    targetCache[target.type] += 1;
  });

  return Object.entries(targetCache)
    .map(([type, count]) => `${count} ${type}${count > 1 ? 's' : ''}`)
    .join(', ');
};
