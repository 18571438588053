import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MetadataUrlStep } from '../../metadata-step';
import { VMwareSamlFormFields } from './vmware-saml-steps';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://tenant.vmwareidentity.com/SAAS/API/1.0/GET/metadata/idp.xml..."
    idpSsoUrlPlaceholder="https://foocorp.workspaceoneaccess.com..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      After saving your SaaS Application, click "Settings" and then "SAML
      Metadata". Click on "Copy URL" next to "Identity Provider (IdP) metadata"
      and paste it below.
    </Text>

    <Img
      className="my-8"
      height={1598}
      src="/images/8456f5b8-f7d7-4809-8698-055a9273bf80.png"
      width={2692}
    />
  </MetadataUrlStep>
);

export const uploadMetadataUrl: StepData<VMwareSamlFormFields> = {
  title: 'Upload Metadata URL',
  component: Component,
  formKeys: [
    'saml_idp_entity_id',
    'saml_idp_metadata_url',
    'saml_idp_url',
    'saml_x509_certificates',
  ],
};
